import Swiper from 'swiper/dist/js/swiper.js';
// import subscribe from './subscribe';
import logEvent from './analytics';
import Popup from './popup';
import yandexKassaInit from './yandex-kassa';

const fbShare = () => {
  FB.ui({
    method: 'share',
    href: location.href,
    hashtag: '#365done',
    mobile_iframe: true,
  }, (response) => {
    if (response && !response.error_message) {
      // sharing completed
      document.querySelector('.js-share-buttons').style.display = 'none';
      document.querySelector('.js-download').style.display = 'block';
    }
  });
};

const showDownload = () => {
  const btns = document.querySelector('.js-share-buttons');
  const container = document.querySelector('.js-download');
  if (btns && container) {
    // sharing completed
    btns.style.display = 'none';
    container.style.display = 'block';
  }
};

const vkShare = (url, winWidth, winHeight) => {
  const winTop = (screen.height / 2) - (winHeight / 2);
  const winLeft = (screen.width / 2) - (winWidth / 2);
  window.open(`http://vk.com/share.php?url=${url}`, 'Post to vk', `top=${winTop},left=${winLeft},toolbar=0,status=0,width=${winWidth},height=${winHeight}`);
  // sharing completed
  setTimeout(showDownload, 5000);
};

const ptShare = (url, winWidth, winHeight) => {
  const winTop = (screen.height / 2) - (winHeight / 2);
  const winLeft = (screen.width / 2) - (winWidth / 2);
  window.open(url, 'Save pin', `top=${winTop},left=${winLeft},toolbar=0,status=0,width=${winWidth},height=${winHeight}`);
  // sharing completed
  setTimeout(showDownload, 5000);
};


document.addEventListener('DOMContentLoaded', () => {
  // swiper init banner
  const galleries = document.querySelectorAll('.swiper-container');
  for (const swiperContainer of galleries) {
    new Swiper(
      swiperContainer,
      {
        // init: false,
        loop: true,
        autoplay: {
          delay: 6000,
        },
        navigation: {
          nextEl: swiperContainer.querySelector('.swiper-button-next'),
          prevEl: swiperContainer.querySelector('.swiper-button-prev'),
        },
        pagination: {
          el: swiperContainer.querySelector('.swiper-pagination'),
          type: 'bullets',
        },
      },
    );
  }

  // toggle mobile menu
  const mobileMenuBtn = document.querySelector('.js-mobile-menu-burger');
  const mobileSearchBtn = document.querySelector('.js-mobile-menu-search');
  const mobileMenuCloseBtn = document.querySelector('.js-mobile-menu-close');

  if (mobileMenuBtn && mobileSearchBtn && mobileMenuCloseBtn) {
    const onClickMobileMenu = (e) => {
      e.preventDefault();
      document.querySelector('body').style.overflowY = 'hidden';
      document.querySelector('header').classList.toggle('header--open');
    };

    const onClickMobileSearch = (e) => {
      e.preventDefault();
      document.querySelector('body').style.overflowY = 'hidden';
      document.querySelector('header').classList.toggle('header--search');
    };

    const onClickMobileMenuClose = (e) => {
      e.preventDefault();
      document.querySelector('body').style.overflowY = 'inherit';
      document.querySelector('header').classList.remove('header--open', 'header--search');
    };

    mobileMenuBtn.addEventListener('click', onClickMobileMenu);
    mobileSearchBtn.addEventListener('click', onClickMobileSearch);
    mobileMenuCloseBtn.addEventListener('click', onClickMobileMenuClose);
  }

  const popups = new Popup();

  // share buttons
  Array.prototype.slice.call(document.querySelectorAll('.js-fb-share'))
    .forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        fbShare();
      });
    });

  Array.prototype.slice.call(document.querySelectorAll('.js-apply-checklist'))
    .forEach((el) => {
      el.addEventListener('click', (e) => {
        logEvent({
          hitType: 'event',
          eventCategory: 'Apply',
          eventAction: 'apply-checklist-click',
          eventLabel: 'apply checklist btn click',
        });
      });
    });

  Array.prototype.slice.call(document.querySelectorAll('.js-vk-share'))
    .forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        vkShare(location.href, 600, 600);
      });
    });

  Array.prototype.slice.call(document.querySelectorAll('.js-pt-share'))
    .forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        const btn = e.currentTarget;
        if (btn.dataset['url']) {
          ptShare(btn.dataset['url'], 600, 600);
        }
      });
    });

  // subscribe(document.querySelector('.js-subscribe-footer'), false);
  // subscribe(document.querySelector('.js-subscribe-popup'), true);

  yandexKassaInit();
});
