const onYandexKassaSubmit = (e) => {
  e.preventDefault();
  const yandexKassaUserEmail = e.target.querySelector('.js-yakassa-user-email');
  const yandexKassaCpsEmail = e.target.querySelector('.js-yakassa-cps-email');
  if (yandexKassaUserEmail && yandexKassaCpsEmail && yandexKassaUserEmail.value) {
    yandexKassaCpsEmail.value = yandexKassaUserEmail.value;
    const yandexKassaReciept = e.target.querySelector('.js-yakassa-receipt');
    if (yandexKassaReciept) {
      yandexKassaReciept.value = JSON.stringify({
        customerContact: yandexKassaUserEmail.value,
        taxSystem: 2,
        items: [{
          quantity: 1,
          price: {
            amount: 100.00,
          },
          tax: 1,
          text: 'Чеклист с сайта 365done.ru',
          paymentSubjectType: 'commodity',
          paymentMethodType: 'full_payment',
        }],
      });
      e.target.submit();
    }
  }
};

export default () => {
// init yandex kassa
  const yandexKassaForm = document.querySelector('.js-yandex-kassa-form');
  if (yandexKassaForm) {
    yandexKassaForm.addEventListener('submit', onYandexKassaSubmit)
  }
};
