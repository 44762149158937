export default class Popup {
  constructor(beforeOpen = () => true) {
    this.beforeOpen = beforeOpen;
    this.popup = document.querySelector('.js-popup');
    if (this.popup) {
      this.overlay = document.querySelector('.js-overlay');
      this.bindEvents();
    }
  }

  bindEvents() {
    Array.prototype.slice.call(document.querySelectorAll('.js-show_popup'))
      .forEach((el) => {
        // popup close btn
        this.closePopupLink = this.popup.querySelector('.js-close');
        this.closePopupLink.addEventListener('click', (e) => {
          e.preventDefault();
          this.closePopup();
        });

        this.popup.addEventListener('click', (e) => {
          if (e.target === this.popup) {
            e.preventDefault();
            this.closePopup();
          }
        });

        // open popup
        el.addEventListener('click', (e) => {
          if (this.beforeOpen()) {
            e.preventDefault();
            this.openPopup();
          }
        });
      });
  }

  openPopup() {
    this.overlay.style.display = 'block';
    this.popup.classList.add('show');
  }

  closePopup() {
    this.popup.classList.remove('show');
    this.overlay.style.display = 'none';
  }
}

